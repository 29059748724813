// Slick Slider
$(document).ready(function(){
  




  // Slider (Teaser-Icon)
	$('.slider-header').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 1,
  		infinite: true,
      fade: true,
      speed:500,
      prevArrow: slider.parent().find('.slick-prev'),
      nextArrow: slider.parent().find('.slick-next'),
  	});
	});

  $('.center').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1.66,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: '10px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: '10px',
          slidesToShow: 1
        }
      }
    ]
  });

  $('.pureimageslider').slick({
    slidesToShow: 1,
    dots: false,
    infinite: true,
    autoplay: true, 
    autoplaySpeed: 6500,
    pauseOnHover: false,
    speed: 1600,
    fade: true,
    prevArrow:"<span class='slick-prev miau'></span>",
    nextArrow:"<span class='slick-next miau'></span>",  

    
  });


  $('.slider-reasons').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true, 
    autoplaySpeed: 6500,
    pauseOnHover: false,
    speed: 1600,
    fade: true,
    prevArrow:"<span class='slick-prev miau'><i class='far fa-arrow-left text-white p-2'></i></span>",
    nextArrow:"<span class='slick-next miau'><i class='far fa-arrow-right text-white p-2'></i></span>",  
  });


  $('.quotes').slick({
    centerMode: true,
    //centerPadding: '60px',
    slidesToShow: 3,
    arrows: true,
    dots: false,
    autoplay: true, 
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 1200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: '10px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: '10px',
          slidesToShow: 1
        }
      }
    ]
  });

  // Slider Full
	$('.slider-full').each(function () {
		var slider = $(this);
    function positionSliderTeaserArrows(slider) {
			var imgHeight = slider.find('img').first().outerHeight();
			var positionTop = (imgHeight / 2) + 'px';
			slider.find('.slick-prev, .slick-next').css('top', positionTop);
    }
    slider.on('init', function (e) {
			positionSliderTeaserArrows(slider);
		});
		slider.slick({
      arrows: true,
      dots: false,
      fade: true,
      infinite: true,
			slidesToShow: 1,
      adaptiveHeight: true,
		});
		slider.on('setPosition', function (e) {
			positionSliderTeaserArrows(slider);
		});
	});  

  // Slider (Teaser-Icon)
	$('.slider-icon-teaser').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 5,
  		infinite: true,
      speed:500,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
        		infinite: true,
            speed:500,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
        		infinite: true,
            speed:500,
          }
        }
      ]
  	});
	});

  // Slider Tiles
	$('.slider-tiles').each(function () {
    var slider = $(this);
		slider.slick({
      arrows: true,
      dots: false,
      fade: true,
      infinite: false,
			slidesToShow: 1,
      adaptiveHeight: true,
      prevArrow: slider.parent().find('.prev-desktop'),
      nextArrow: slider.parent().find('.next-desktop'),
      responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: true,
            dots: false,
            fade: true,
            infinite: false,
            slidesToShow: 1,
            adaptiveHeight: true,
            prevArrow: slider.find('.tiles-img-wrapper .prev-mobil'),
            nextArrow: slider.find('.tiles-img-wrapper .next-mobil')
					}
				},
			]
    });
	});  

	// Teaser Slider
	$('.slider-teaser').each(function () {
		var slider = $(this);
		function positionSliderTeaserArrows(slider) {
			var imgHeight = slider.find('.teaser-img').first().outerHeight();
			var positionTop = (imgHeight / 2) + 'px';
			slider.find('.slick-prev, .slick-next').css('top', positionTop);
			//console.log('ArrowsTop: '+positionTop);
    }
    slider.on('init', function (e) {
			positionSliderTeaserArrows(slider);
			//console.log('Init');
			$.fn.matchHeight._update();
		});
  	slider.slick({
      infinite: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			responsive: [
				{
					breakpoint: 1380,
					settings: {
						infinite: false,
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						infinite: false,
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						infinite: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: false
					}
				}
			]
		});
		slider.on('setPosition', function (e) {
      positionSliderTeaserArrows(slider);
      $.fn.matchHeight._update();
			//console.log('SetPosition');
		});
  });  
  
  // Slider Gallery
  $('.slider-gallery').each(function () {
		var slider = $(this);
		slider.slick({
      arrows: true,
      dots: false,
      fade: true,
      infinite: true,
			slidesToShow: 1,
      adaptiveHeight: false,
		});
	});  
 
  // Slider History 
	$('.slider-history-content').each(function () {
    var slider = $(this);
    var progress = slider.parent().find('.progress-bar .progress');
    slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      // Progress-Bar
      var oneSlideProgressWidth = 100/slick.slideCount;
      progress.css('width', (i*oneSlideProgressWidth)+'%');
    });
  	slider.slick({
      arrows: true,
      dots: false,
      infinite: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      asNavFor: '.slider-history-nav',
      appendArrows: slider.parent().find('.slider-history-arrows')
    });
    slider.on('setPosition', function(e) {
      //console.log('SetPosition');
      var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
      var numberOfSlides = $(slider).find('.slick-slide').length;
      //console.log('slidesShown: '+slidesShown);
      //console.log('numberOfSlides: '+numberOfSlides);
      if (slidesShown === numberOfSlides || slidesShown > numberOfSlides) {
        progress.css('width', (100/numberOfSlides)+'%');
      }
    });
	});  
  // Slider History Nav
	$('.slider-history-nav').each(function () {
  	var slider = $(this);
  	slider.slick({
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: false,
      focusOnSelect: true,
      asNavFor: '.slider-history-content',
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: false,
            focusOnSelect: true,
            asNavFor: '.slider-history-content',
          }
        },
        {
          breakpoint: 1200,
          settings: {
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: false,
            focusOnSelect: true,
            asNavFor: '.slider-history-content',
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: false,
            focusOnSelect: true,
            asNavFor: '.slider-history-content',
          }
        },
        {
          breakpoint: 500,
          settings: {
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: false,
            focusOnSelect: true,
            asNavFor: '.slider-history-content',
          }
        }
      ]
    });
    slider.on('setPosition', function(e) {
      //console.log('SetPosition');
      var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
      var numberOfSlides = $(slider).find('.slick-slide').length;
      //console.log('slidesShown: '+slidesShown);
      //console.log('numberOfSlides: '+numberOfSlides);
      if (slidesShown === numberOfSlides || slidesShown > numberOfSlides) {
        $(slider).find('.slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
      }
    });
	});  

});