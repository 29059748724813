// Basic Functions
$(document).ready(function(){
    
	// ##############################################
	// #################### forms ###################
	// ##############################################  


  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});

    
  $('input, textarea').each(function(){
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });
  
  $('input, textarea').focusin(function(){
    $(this).addClass("focus");
  });

  $('input, textarea').focusout(function(){
    $(this).removeClass("focus");
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });

  $('a[data-toggle="tablist"]').on('shown.bs.tab', function (e) {
    e.target // newly activated tab
    e.relatedTarget // previous active tab
  })
  
  $('form').attr('data-parsley-validate', '');
  $('form').attr('id', 'form');
  $('img').addClass("img-fluid");
 $(".w-100 img.img-fluid").addClass("w-100")
  
	// ##############################################
	// ################## On Screen #################
	// ##############################################  
  
	



});